import MainNav from '../components/MainNav'

function Products() {
    return (
      <div className="position-absolute top-50 start-50 translate-middle">
        <h1>This is the products page</h1>
      </div>
    );
  }
  
  export default Products;