import { Outlet } from 'react-router-dom'
import MainNav from '../components/MainNav';

function RootLayout() {
    return (
      <div>
        <MainNav />
        <Outlet />
      </div>
    );
  }
  
  export default RootLayout;