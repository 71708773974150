function Footer() {
    return (
        <footer className="bg-dark text-center text-white rounded fixed-bottom m-3">
        <div className="text-center p-1">
          <p>Email: <a href = "mailto: ernest@nareworld.com">info@nareworld.com</a></p>
          <p>© 2023 NareWorld. All rights reserved.</p>
        </div>
      </footer>
    );
  }
  
  export default Footer;