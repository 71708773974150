import { Link } from 'react-router-dom'


function MainNav() {
    return (
    <div>
         <nav class="navbar navbar-expand-sm navbar-dark bg-dark rounded p-4 m-3">
            <div class="container-fluid">
                <Link class="navbar-brand" to="/"><img src='favicon.ico' width="20px" height="20px"></img>NareWorld</Link>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="mynavbar">
                    <ul class="navbar-nav me-auto">
                        <li class="nav-item">
                            <Link class="navbar-brand" to="/products">Products</Link>
                        </li>
                        <li class="nav-item">
                            <Link class="navbar-brand" to="/tests">Tests</Link>
                        </li>
                    </ul>
                    <form class="d-flex">
                        <input class="form-control me-2" type="text" placeholder="Search" />
                        <button class="btn btn-light" type="button">Search</button>
                    </form>
                </div>
            </div>
        </nav> 
    </div>
    );
  }
  
  export default MainNav;