import MainNav from '../components/MainNav'

function ErrorPage() {
    return (
        <>
        <MainNav />
            <div class="position-absolute top-50 start-50 translate-middle">
                <h1>An error occured</h1>
                <p>Page not found</p>
                <p>Please contact: <a href = "mailto: ernest@nareworld.com">support@nareworld.com</a></p>
            </div>
        </>
    );
  }
  
  export default ErrorPage;